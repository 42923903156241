import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeroContainer from "../components/containers/HeroContainer"
import Section from "../components/Section"
import ColumnContainer, {
  Column,
} from "../components/containers/ColumnContainer"
import Button from "../components/Button"
import * as Icons from "../icons"
import Header from "../components/Header"
import CallToActionContainer from "../components/containers/CallToActionContainer"
import FooterContainer from "../components/containers/FooterContainer"
import Portrait from "../components/Portrait"
import BoxContainer, {
  Row as BoxContainerRow,
} from "../components/containers/BoxContainer"
import NumberItem from "../components/NumberItem"
import CellImage from "../components/CellImage"
import Results from "../components/Results"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      fileMatthias: file(relativePath: { eq: "matthias.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fileJonathan: file(relativePath: { eq: "jonathan.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fileExample: file(relativePath: { eq: "example.jpg" }) {
        childImageSharp {
          # i.e. the max width of your container is 700 pixels.
          #
          # Other options include maxHeight (set both maxWidth and maxHeight to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 900) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      fileRawExample: file(relativePath: { eq: "rawExample.jpg" }) {
        childImageSharp {
          # i.e. the max width of your container is 700 pixels.
          #
          # Other options include maxHeight (set both maxWidth and maxHeight to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 900) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      fileRawExampleTagged: file(relativePath: { eq: "rawExampleTagged.png" }) {
        childImageSharp {
          # i.e. the max width of your container is 700 pixels.
          #
          # Other options include maxHeight (set both maxWidth and maxHeight to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 900) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // console.log("-- data", data)

  return (
    <Layout>
      <Seo title="Home" />
      <Section image>
        <Header logo="Neuralab">
          <Button minor href="//app.neuralab.de/login">
            login
          </Button>
        </Header>
        <HeroContainer
          title="Fast and automatic cell image analysis"
          sub="Microscopy image analysis made easy"
          action={
            <Button href="//app.neuralab.de/signup">Get started now</Button>
          }
        />
      </Section>
      <Section>
        <BoxContainer
          title="Automated cell detection"
          sub="Our AI detects your cells and counts & classifies morphological features"
        >
          <BoxContainerRow
            num={<NumberItem>{1}</NumberItem>}
            title="Upload raw image"
            desc="Simply upload your raw microscope images to the cloud"
          >
            <CellImage fluid={data.fileRawExample.childImageSharp.fluid} />
          </BoxContainerRow>
          <BoxContainerRow
            num={<NumberItem>{2}</NumberItem>}
            title="Automatic cell classification"
            desc="The Neuralab AI performs a rapid classification of the morphological features"
          >
            <CellImage
              fluid={data.fileRawExampleTagged.childImageSharp.fluid}
            />
          </BoxContainerRow>
          <BoxContainerRow
            num={<NumberItem>{3}</NumberItem>}
            title="Download results"
            desc="The results of the classification can be exported to Excel for your further research"
          >
            <Results
              headline="Morphological results"
              data={[
                { title: "Flat cells", value: 21, color: '#31C43F' },
                { title: "Round cells", value: 11, color: '#C43144' },
                { title: "Intermediate cell state", value: 1, color: '#454545' },
              ]}
            />
          </BoxContainerRow>
        </BoxContainer>
      </Section>

      <Section>
        <ColumnContainer
          title="Built for your lab"
          sub="Our AI detects your cells, counts and classifies morphological features"
        >
          <Column title="Artificial Intelligence" icon={<Icons.Ai />}>
            Our technology is based on the latest achievements in AI research.
            Our AI exceeds the performance of a human expert.
          </Column>
          <Column title="High performance" icon={<Icons.Data />}>
            We deliver robust, unbiased and reproducible results.
          </Column>
          <Column title="Easy to use" icon={<Icons.Easy />}>
            Discover our easy to use platform. Just drag and drop your images
            and export your results.
          </Column>
        </ColumnContainer>
      </Section>

      <Section minor>
        <CallToActionContainer>
          <h3>It's free for academia. Get started!</h3>
          <div>
            <Button href="//app.neuralab.de/signup">
              Create your account now
            </Button>
          </div>
        </CallToActionContainer>
      </Section>

      {/* <Section minor>
        <CallToActionContainer>
          <div>
            <Img fluid={data.fileExample.childImageSharp.fluid} />
          </div>
        </CallToActionContainer>
      </Section> */}
      <Section>
        <ColumnContainer
          title="Our team"
          sub="We are experts in Deep Learning, Cloud Computing, UI/UX and the Life Sciences"
        >
          <Column
            title="Dr. Matthias Rapp"
            icon={<Portrait fixed={data.fileMatthias.childImageSharp.fixed} />}
          >
            Matthias is a machine learning expert with a broad experience across
            different domains.
          </Column>
          <Column
            title="Jonathan Häberle, MSc."
            icon={<Portrait fixed={data.fileJonathan.childImageSharp.fixed} />}
          >
            Jonathan is a software engineer and has been implementing innovative
            UI intensive products for years.
          </Column>
        </ColumnContainer>
      </Section>
      {/* <Section minor>
        <CallToActionContainer>
          <h3>Getting started!</h3>
          <div>
            <Button href="//app.neuralab.de/signup">
              Create your account now
            </Button>
          </div>
        </CallToActionContainer>
      </Section> */}
      <Section dark>
        <FooterContainer>
          <Button text invert href="mailto:mail@neuralab.de">
            Contact us
          </Button>
          <Button text invert href="/terms">
            Terms of use
          </Button>
          <Button text invert href="/imprint">
            Imprint
          </Button>
        </FooterContainer>
      </Section>
    </Layout>
  )
}

export default IndexPage
