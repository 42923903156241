import React from "react"

import "./BoxContainer.scss"

export const Row = ({ num, title, desc, children }) => (
  <>
    <div className="first">
      <div className="num">{num}</div>
      <div className="text">
        <div className="title">{title}</div>
        <div className="desc">{desc}</div>
      </div>
    </div>
    <div>{children}</div>
  </>
)

const BoxContainer = ({ children, title, sub }) => (
  <div className="BoxContainer">
    <h2 className="title">{title}</h2>
    <h3 className="sub">{sub}</h3>
    <div className="box">{children}</div>
  </div>
)

export default BoxContainer
