import React from "react"
import cn from 'classname'

import "./ColumnContainer.scss"

export const Column = ({ icon, title, children }) => (
  <div className="column">
    <div className="icon">{icon}</div>
    <div className="title">{title}</div>
    <div className="content">{children}</div>
  </div>
)

const ColumnContainer = ({ title, sub, children }) => (
  <div className={cn("ColumnContainer", `num-column-${React.Children.count(children)}`)}>
    <h2 className="title">{title}</h2>
    <h3 className="sub">{sub}</h3>
    <div className="columns">{children}</div>
  </div>
)

export default ColumnContainer
