import React from "react"
import { PieChart } from "react-minimal-pie-chart"

import "./Results.scss"

const Results = ({ headline, data }) => (
  <div className="Results">
    <div className="inner">
      <div className="headline">{headline}</div>
      <div className="body">
        <div className="pie">
          <PieChart lineWidth={30} data={data} />
        </div>
        <div className="details">
          {data.map(d => (
            <div className="line">
              <div className="dot" style={{ color: d.color }} />
              <div className="title">{d.title}</div>
              <div className="number">{d.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default Results
