import * as React from "react"

function SvgData(props) {
  return (
    <svg width={100} height={100} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M50.5 40.097c-5.08 0-9.371 5.45-9.371 11.903h2.677c0-4.932 3.128-9.258 6.694-9.258v-2.645z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          d="M35.774 52c0 9.48 6.607 17.194 14.726 17.194 8.12 0 14.726-7.714 14.726-17.194 0-9.48-6.607-17.194-14.726-17.194-8.12 0-14.726 7.714-14.726 17.194zm26.774 0c0 8.021-5.405 14.548-12.048 14.548S38.452 60.021 38.452 52 43.857 37.452 50.5 37.452 62.548 43.979 62.548 52z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={53.177}
          cy={55.968}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={54.516}
          cy={44.065}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={45.145}
          cy={58.613}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={49.161}
          cy={49.355}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={30.419}
          cy={57.29}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={66.565}
          cy={63.903}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={69.242}
          cy={42.742}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={49.161}
          cy={29.516}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={58.532}
          cy={52}
          rx={1.339}
          ry={1.323}
        />
        <ellipse
          fill="currentColor"
          fillRule="nonzero"
          cx={50.5}
          cy={62.581}
          rx={1.339}
          ry={1.323}
        />
        <path
          d="M92 21.58v-2.645h-2.677V11h-2.678v7.935h-2.677v-5.29H81.29v5.29h-2.677V11h-2.678v7.935h-2.677V16.29h-2.677v2.645h-2.678v-5.29h-2.677v5.29h-.554l-2.877 2.843c-3.504-1.823-7.335-2.843-11.295-2.843a24.27 24.27 0 00-8.554 1.562l-.656.246-.61 2.423a7.659 7.659 0 01-3.234 4.54 7.783 7.783 0 01-1.973.935c-4.893 1.525-8.869 5.2-10.635 9.829-1.625 4.263-2.45 8.816-2.45 13.53 0 12.385 5.821 23.194 14.413 28.857L35.22 82.42H9v2.646h2.677V93h2.678v-7.935h2.677v5.29h2.678v-5.29h2.677V93h2.678v-7.935h2.677v2.645h2.677v-2.645h2.678v5.29h2.677v-5.29h.554l2.847-2.812c3.467 1.802 7.297 2.812 11.325 2.812 11.587 0 22.145-8.55 26.27-21.274.53-1.627.356-3.452-.474-5.007a14.451 14.451 0 01-1.7-6.784c0-2.354.587-4.7 1.7-6.782.83-1.557 1.004-3.382.475-5.007-2.39-7.37-6.944-13.324-12.597-17.043l1.606-1.587H92zM74.222 41.018c.313.967.209 2.021-.296 2.967A17.109 17.109 0 0071.92 52c0 2.783.695 5.554 2.007 8.017.505.945.61 1.999.296 2.967C70.45 74.61 60.918 82.419 50.5 82.419c-3.296 0-6.443-.761-9.336-2.132l4.928-4.868-1.893-1.87-5.47 5.404C30.615 73.863 25.065 63.696 25.065 52c0-4.394.767-8.632 2.28-12.599 1.48-3.876 4.82-6.955 8.937-8.238a10.484 10.484 0 002.652-1.255 10.3 10.3 0 004.345-6.103l.268-1.067A21.524 21.524 0 0150.5 21.58c3.241 0 6.396.757 9.319 2.149l-4.91 4.851 1.892 1.87 5.437-5.37c5.367 3.363 9.719 8.953 11.984 15.936z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          d="M59.871 69.194c-2.214 0-4.016 1.78-4.016 3.967 0 2.188 1.802 3.968 4.016 3.968s4.016-1.78 4.016-3.968c0-2.187-1.802-3.967-4.016-3.967zm0 5.29a1.332 1.332 0 01-1.339-1.323c0-.73.601-1.322 1.339-1.322s1.339.592 1.339 1.322c0 .73-.601 1.323-1.339 1.323zM38.452 66.548c-2.215 0-4.017 1.78-4.017 3.968 0 2.188 1.802 3.968 4.017 3.968 2.214 0 4.016-1.78 4.016-3.968 0-2.187-1.802-3.968-4.016-3.968zm0 5.29a1.332 1.332 0 01-1.34-1.322c0-.73.602-1.322 1.34-1.322.737 0 1.338.592 1.338 1.322 0 .73-.6 1.323-1.338 1.323zM35.774 42.742c0-2.188-1.802-3.968-4.016-3.968s-4.016 1.78-4.016 3.968c0 2.187 1.802 3.968 4.016 3.968s4.016-1.78 4.016-3.968zm-4.016 1.323a1.332 1.332 0 01-1.339-1.323c0-.73.601-1.323 1.34-1.323.737 0 1.338.593 1.338 1.323s-.601 1.323-1.339 1.323z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path d="M0 0h100v100H0z" />
      </g>
    </svg>
  )
}

export default SvgData
